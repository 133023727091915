import React from 'react'
import { onMobile } from 'src/styles'
import styled, { css } from 'styled-components'

export const About = () => (
  <Container>
    <Name>BEN SHIRAKAWA</Name>
    <Title>PHOTOGRAPHER</Title>
    <Awards>
      <AwardsTitle>HISTORY AWARDS</AwardsTitle>
      <AwardsItem>{'RED BULL ILLUME 2019\r\nSEMI-FINALIST'}</AwardsItem>
      <AwardsItem>
        {'NIKKOR PHOTO CONTEST 64th\r\nTHREE PRIZES WINNING'}
      </AwardsItem>
    </Awards>
  </Container>
)
const Container = styled.div`
  text-align: center;
  user-select: text;
  white-space: pre;
  margin: 14px;
  ${onMobile(css`
    margin: 5px 14px;
  `)}
`
const Name = styled.div`
  font-size: var(--font-size-2xlarge);
`

const Title = styled.div`
  font-size: var(--font-size-small);
  font-weight: 300;
`

const Awards = styled.div`
  margin-top: 47px;
  width: 100%;
  ${onMobile(css`
    margin-top: 16px;
  `)}
`

const AwardsTitle = styled.div`
  font-size: var(--font-size-xlarge);
  padding-bottom: 14px;
  border-bottom: 1px dashed #eaeaea;
`
const AwardsItem = styled.div`
  font-size: var(--font-size-small);
  font-weight: 300;
  margin-top: 36px;
  ${onMobile(css`
    margin-top: 18px;
  `)}
`
