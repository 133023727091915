import React, { FC } from 'react'
import { CloseIcon } from 'src/assets/svgs'
import { MenuContents, MenuItems } from 'src/components/MenuContent'
import { HederMenuProps } from 'src/components/MenuContent/HeaderMenu'
import { Title } from 'src/components/Title'
import { AnimationContext } from 'src/context/AnimationContext'
import { onMobilePortrait, onTablet } from 'src/styles'
import styled, { css } from 'styled-components'

type HederProps = HederMenuProps
export const Header: FC<HederProps> = ({ openedItem, toggleItem }) => {
  const { animation, setAnimation } = React.useContext(AnimationContext)
  return (
    <HeaderContainer>
      <Title
        id="rec"
        className={animation || 'default'}
        onClick={() => setAnimation(animation ? '' : 'focusing')}
      >
        ● BEN_SHIRAKAWA
      </Title>
      <HeaderControl>
        <HeaderControlItem
          className={openedItem === 'PLAY' ? 'active' : ''}
          onClick={(e) => toggleItem(e, 'PLAY')}
        >
          PLAY
        </HeaderControlItem>
        <HeaderControlItem
          className={
            openedItem === 'MENU'
              ? 'active'
              : // @ts-ignore
              MenuItems.includes(openedItem)
              ? 'active closeButton'
              : ''
          }
          onClick={(e) => toggleItem(e, 'MENU')}
        >
          {
            // @ts-ignore
            !MenuItems.includes(openedItem) ? 'MENU' : <CloseIcon />
          }
        </HeaderControlItem>
        {openedItem && (
          <MenuContents openedItem={openedItem} toggleItem={toggleItem} />
        )}
      </HeaderControl>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  padding: 4vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 3;
  ${onMobilePortrait(css`
    padding: 4vw;
  `)}
`
const HeaderControl = styled.div`
  display: flex;
  flex-direction: row;
`

const HeaderControlItem = styled.button`
  font-size: var(--font-size-medium);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 2px solid #eaeaea;
  border-radius: 12px;
  width: 100px;
  height: 50px;
  margin-left: 12px;
  overflow: hidden;
  transition: width 0.5s, margin-left 0.5s, background-color 0.5s,
    backdrop-filter 0.5s, border 0.5s;

  &.active {
    border: none;
    background-color: rgba(0, 0, 0, 50%);
    backdrop-filter: blur(10px);
  }
  &.closeButton {
    width: 50px;
    margin-left: 62px;
  }

  ${onTablet(css`
    width: 60px;
    height: 30px;
    margin-left: 6px;
    &.closeButton {
      width: 30px;
      margin-left: 36px;
      svg {
        height: 50%;
      }
    }
  `)}
`
