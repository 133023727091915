import { css } from 'styled-components'
import {
  barTransitionAnimation,
  imageBlurAnimation,
  imageFilterAnimation,
  rotateAnimation,
  rotateAnimationDottedCircle,
  rotateAnimationY,
  translateAnimation,
} from './settings'

const bgImageBlurAnimation = css`
  #bgImage.focusing {
    ${imageBlurAnimation}
  }
  #bgImageOverlay.focusing {
    ${imageFilterAnimation}
  }
`
const aimIconRotateAnimation = css`
  #aimIconX.focusing {
    ${rotateAnimation(true)}
  }
  #aimIconY.focusing {
    ${rotateAnimationY()}
  }
  #aimIconDottedCircle.focusing {
    ${rotateAnimationDottedCircle()}
  }
`

const aimIconTranslateAnimation = css`
  #aimIconTop.focusing {
    ${translateAnimation({ yTo: -720 })}
  }
  #aimIconBottom.focusing {
    ${translateAnimation({ yTo: 720 })}
  }
`

export const imageFocusingAnimation = css`
  ${bgImageBlurAnimation}
  ${aimIconRotateAnimation}
  ${aimIconTranslateAnimation}
  #aimIconLeft.focusing,
  #aimIconRight.focusing {
    ${barTransitionAnimation}
  }
`

export const addFocusingAnimationEndLisnter = (callback: () => void) => {
  const finalAnimation = document.querySelector('#aimIconRight')
  if (!finalAnimation) {
    setTimeout(() => addFocusingAnimationEndLisnter(callback), 100)
    return
  }
  // @ts-ignore
  finalAnimation.onanimationend = callback
}
