import React, { FC, memo } from 'react'
import { onTablet } from 'src/styles'
import styled, { css } from 'styled-components'
import { MenuItem, MenuItems } from '.'

export type HederMenuProps = {
  openedItem: MenuItem
  toggleItem: (e: React.MouseEvent | undefined, item: MenuItem) => void
}
export const HeaderMenu: FC<HederMenuProps> = ({ openedItem, toggleItem }) => {
  return (
    <HeaderMenuContainer>
      <HederMenuItemsWithMemo openedItem={openedItem} toggleItem={toggleItem} />
    </HeaderMenuContainer>
  )
}

const HeaderMenuItems: FC<HederMenuProps> = ({ openedItem, toggleItem }) => {
  return (
    <>
      {MenuItems.map((item) => (
        <HeaderMenuItem
          key={item}
          item={item}
          openedItem={openedItem}
          toggleItem={toggleItem}
        />
      ))}
    </>
  )
}
type HeaderMenuItemProps = HederMenuProps & {
  item: MenuItem
}
const HeaderMenuItem: FC<HeaderMenuItemProps> = ({
  item,
  openedItem,
  toggleItem,
}) => (
  <Button
    onClick={(e) => toggleItem(e, item)}
    className={(openedItem === item && 'active') || ''}
  >
    {item}
  </Button>
)

const HederMenuItemsWithMemo = memo(HeaderMenuItems)

const HeaderMenuContainer = styled.div`
  color: #eaeaea;
  font-size: var(--font-size-medium);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding-left: 33px;
  ${onTablet(css`
    padding-left: 16px;
  `)}
`

const Button = styled.button`
  position: relative;
  :hover,
  :focus {
    ::before {
      content: '';
      position: absolute;
      top: -3px;
      bottom: 0;
      left: -34px;
      margin: auto;
      opacity: 0.5;
      border: 10px solid transparent;
      border-left-color: white;
      height: 20px;
      ${onTablet(css`
        left: -14px;
        border: 7px solid transparent;
        height: 14px;
      `)}
    }
  }
`
