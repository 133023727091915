import React, { createContext, FC, useState } from 'react'
import { ContactFormInfo } from './ContactFormInfo'

type FormContextProps = {
  contactInfo: ContactFormInfo
  setContactInfo: (info: ContactFormInfo) => void
}
export const FormContext = createContext<FormContextProps>({
  contactInfo: ContactFormInfo.initialize(),
  setContactInfo: () => {},
})

export const FormContextProvider: FC = ({ children }) => {
  const [contactInfo, setContactInfo] = useState<ContactFormInfo>(
    ContactFormInfo.initialize()
  )
  return (
    <FormContext.Provider value={{ contactInfo, setContactInfo }}>
      {children}
    </FormContext.Provider>
  )
}
