import React from 'react'
import styled from 'styled-components'
import { Frame } from './Frame'

export const FinderOverlay: React.FC = () => {
  return (
    <FinderContainer>
      <Frame />
    </FinderContainer>
  )
}

const FinderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
