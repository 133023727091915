import { css } from 'styled-components'

export const imageBlurElement = (durationPct: number, blurPx: number) => `
  ${durationPct}% {;
    filter: blur(${blurPx}px);
  }
`
export const imageFilterElement = (durationPct: number, blurPx: number) => `
  ${durationPct}% {
    background-color: rgba(0, 0, 0, ${blurPx}%);
  }
`

export const rotateElement = (
  durationPct: number,
  degree: number,
  reverse?: boolean
) => `
  ${durationPct}% {
    transform: rotate(${reverse ? '-' : ''}${degree}deg);
  }
`

export const rotateAndScaleElement = (
  durationPct: number,
  degree: number,
  scale: number,
  reverse?: boolean
) => `
  ${durationPct}% {
    transform: rotate(${
      reverse ? '-' : ''
    }${degree}deg) scale(${scale}, ${scale});
  }
`

export type TranslateAnimationOption = {
  xTo?: number
  yTo?: number
}
export const translateElement = (
  durationPct: number,
  xTo: number,
  yTo: number
) => `
  ${durationPct}% {
    transform: translate(${xTo}%, ${yTo}%);
  }
`

export const barTransition = (duration: number, delay: number) => css`
  .bar {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: barTransition ${duration}s ease-in-out ${delay}s forwards;
  }
  @keyframes barTransition {
    /* 90% {
      stroke-dashoffset: 1000;
    } */
    100% {
      stroke-dashoffset: 0;
    }
  }
`
