import { onTablet } from 'src/styles'
import { css } from 'styled-components'

const menuFadeInVerticalDuration = 0.2
const menuFadeInHorizontalDuration = 0.2
const menuFadeInBlurDuration = 0.7

export const menuFadeInAnimation = css`
  animation: menuFadeInVertical ${menuFadeInVerticalDuration}s ease-in-out
      forwards,
    menuFadeInHorizontal ${menuFadeInHorizontalDuration}s ease-in-out
      ${menuFadeInVerticalDuration}s forwards,
    menuFadeInBlur ${menuFadeInBlurDuration}s linear
      ${menuFadeInVerticalDuration}s forwards;

  @keyframes menuFadeInVertical {
    0% {
      opacity: 0.5;
      clip-path: inset(0 51px 100% calc(100% - 53px) round 12px);
    }
    100% {
      opacity: 1;
      clip-path: inset(0 51px 0 calc(100% - 53px) round 12px);
    }
  }
  ${onTablet(css`
    @keyframes menuFadeInVertical {
      0% {
        opacity: 0.5;
        clip-path: inset(0 30px 100% calc(100% - 32px) round 12px);
      }
      100% {
        opacity: 1;
        clip-path: inset(0 30px 0 calc(100% - 32px) round 12px);
      }
    }
  `)}
  &.PLAY {
    animation: menuFadeInVerticalPlay ${menuFadeInVerticalDuration}s ease-in-out
        forwards,
      menuFadeInHorizontal ${menuFadeInHorizontalDuration}s ease-in-out
        ${menuFadeInVerticalDuration}s forwards,
      menuFadeInBlur ${menuFadeInBlurDuration}s linear
        ${menuFadeInVerticalDuration}s forwards;
    @keyframes menuFadeInVerticalPlay {
      0% {
        opacity: 0.5;
        clip-path: inset(0 161px 100% calc(100% - 163px) round 12px);
      }
      100% {
        opacity: 1;
        clip-path: inset(0 161px 0 calc(100% - 163px) round 12px);
      }
    }
    ${onTablet(css`
      @keyframes menuFadeInVerticalPlay {
        0% {
          opacity: 0.5;
          clip-path: inset(0 95px 100% calc(100% - 97px) round 12px);
        }
        100% {
          opacity: 1;
          clip-path: inset(0 95px 0 calc(100% - 97px) round 12px);
        }
      }
    `)}
  }
  @keyframes menuFadeInHorizontal {
    100% {
      clip-path: inset(0 0 0 round 12px);
    }
  }
  @keyframes menuFadeInBlur {
    100% {
      backdrop-filter: blur(10px);
    }
  }
`

export const menuFadeOutAnimation = css`
  &.CLOSE {
    animation: menuFadeOutHorizontalOnClose 0.2s ease-in-out forwards,
      menuFadeOutVerticalOnClose 0.2s ease-in-out 0.2s forwards;
    @keyframes menuFadeOutHorizontalOnClose {
      100% {
        clip-path: inset(0 51px 0 calc(100% - 53px) round 12px);
      }
    }
    @keyframes menuFadeOutVerticalOnClose {
      100% {
        clip-path: inset(0 51px 100% calc(100% - 53px) round 12px);
      }
    }
    ${onTablet(css`
      @keyframes menuFadeOutHorizontalOnClose {
        100% {
          clip-path: inset(0 30px 0 calc(100% - 32px) round 12px);
        }
      }
      @keyframes menuFadeOutVerticalOnClose {
        100% {
          clip-path: inset(0 30px 100% calc(100% - 32px) round 12px);
        }
      }
    `)}
  }
  &.CLOSE_PLAY {
    animation: menuFadeOutHorizontalOnClosePlay 0.2s ease-in-out forwards,
      menuFadeOutVerticalOnClosePlay 0.2s ease-in-out 0.2s forwards;
    @keyframes menuFadeOutHorizontalOnClosePlay {
      100% {
        clip-path: inset(0 161px 0 calc(100% - 163px) round 12px);
      }
    }
    @keyframes menuFadeOutVerticalOnClosePlay {
      100% {
        clip-path: inset(0 161px 100% calc(100% - 163px) round 12px);
      }
    }
    ${onTablet(css`
      @keyframes menuFadeOutHorizontalOnClosePlay {
        100% {
          clip-path: inset(0 95px 0 calc(100% - 97px) round 12px);
        }
      }
      @keyframes menuFadeOutVerticalOnClosePlay {
        100% {
          clip-path: inset(0 95px 100% calc(100% - 97px) round 12px);
        }
      }
    `)}
  }
  &.hidden {
    animation: menuFadeOutVertical 1s ease-in-out forwards;
    @keyframes menuFadeOutVertical {
      0% {
        backdrop-filter: blur(10px);
        clip-path: inset(0 0 round 12px);
      }
      100% {
        clip-path: inset(0 0 100% round 12px);
      }
    }
  }
`

const contentFadeInVerticalDuration =
  menuFadeInBlurDuration - menuFadeInHorizontalDuration

export const contentFadeInAnimation = css`
  animation: contentFadeInVertical ${contentFadeInVerticalDuration}s linear
    forwards;
  @keyframes contentFadeInVertical {
    100% {
      clip-path: inset(0);
    }
  }
`
