import React from 'react'
import { AnimationContextProvider } from 'src/context/AnimationContext'
import { FormContextProvider } from 'src/context/FormContext'
import { ImageContextProvider } from 'src/context/ImageContextProvider'
import { TopLayout } from 'src/layouts/TopLayout'

const App = () => (
  <AnimationContextProvider>
    <ImageContextProvider>
      <FormContextProvider>
        <TopLayout />
      </FormContextProvider>
    </ImageContextProvider>
  </AnimationContextProvider>
)

export default App
