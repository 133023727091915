import React, { useContext, useEffect, useState } from 'react'
import { BackgroundImage } from 'src/components/Image'
import { AnimationContext } from 'src/context/AnimationContext'
import { ImageContext } from 'src/context/ImageContextProvider'

export const Background = () => {
  const { animation } = useContext(AnimationContext)
  // const [showNext, setShowNext] = useState(true)
  const [showNext, setShowNext] = useState(false)
  const { bgOptions, bgImage, bgGlitchAction, toNextAction } = useContext(
    ImageContext
  )
  useEffect(() => {
    if (bgGlitchAction.isGlitching) setShowNext(!showNext)
  }, [bgGlitchAction])
  return (
    <>
      <BackgroundImage
        id="bgImage"
        className={animation}
        image={bgImage}
        bgImageOptions={bgOptions}
        bgGlitchAction={bgGlitchAction}
        onLoad={toNextAction}
      />
    </>
  )
}
