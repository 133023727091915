import React, { useContext } from 'react'
import { ImageContext } from 'src/context/ImageContextProvider'
import { MOBILE_WIDTH, onMobile, onMobileLandscape } from 'src/styles'
import styled, { css } from 'styled-components'

const MeterArray = Array.from(new Array(61)).map((_, i) => i / 3 - 10)
const isEdge = (meter: number) => meter === 0 || Math.abs(meter) === 10
const isActiveOrEdge = (meter: number, level: number) => {
  if (isEdge(meter)) return true
  return meter > 0 ? level >= meter : level <= meter
}
export const LevelDetector = () => {
  const { level, setLevel } = useContext(ImageContext)
  return (
    <LevelDetectorContainer>
      <MeterBoxContainer>
        {MeterArray.map((meter) => {
          return (
            <MeterBox key={meter}>
              <Tick isActiveOrEdge={isActiveOrEdge(meter, level)} />
              <NumberBox>
                {isEdge(meter) && (meter > 0 ? `+${meter}` : meter)}
              </NumberBox>
            </MeterBox>
          )
        })}
        <Marker level={level} />
      </MeterBoxContainer>
      <FloatIconControlInput
        type="range"
        min={-10}
        max={10}
        step={0.5}
        value={level}
        onChange={(e) => setLevel(+e.target.value)}
      />
    </LevelDetectorContainer>
  )
}
const LevelDetectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${onMobileLandscape(css`
    visibility: hidden;
  `)}
`
const MeterBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`
const Marker = styled.div<{ level: number }>`
  position: absolute;
  top: 3px;
  left: ${({ level }) => 183 + level * 18 - ((level * 2) % 2) * 3}px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    left: ${({ level }) => 122 + level * 12 - ((level * 2) % 2) * 2}px;
  }
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -200%);
    border-bottom: 3px solid #eaeaea;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
  }
  :after {
    content: '';
    bottom: -3px;
    left: 0;
    position: absolute;
    transform: translate(-50%, 300%);
    border-top: 3px solid #eaeaea;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    ${onMobile(css`
      bottom: -1px;
    `)}
  }
`
const FloatIconControlInput = styled.input`
  position: absolute;
  appearance: none;
  width: 430px;
  height: 1px;
  padding: 16px;
  margin: 0;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    color: transparent;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  ${onMobile(css`
    width: 304px;
  `)}
`
const MeterBox = styled.div`
  position: relative;
  font-size: var(--font-size-2xsmall);
  margin: 2px;
  ${onMobile(css`
    margin: 1px;
  `)}
`
const NumberBox = styled.div`
  position: absolute;
  transform: translate(-25%, 20%);
`
const TickActiveOrEdge = css`
  position: relative;
  width: 1px;
  border: 1px solid #eaeaea;
  background-color: #eaeaea;
`
const Tick = styled.div<{ isActiveOrEdge: boolean }>`
  width: 1px;
  border: 1px solid #999999;
  background-color: #999999;
  height: 8px;
  ${({ isActiveOrEdge }) => isActiveOrEdge && TickActiveOrEdge}
`
