import React, { useContext } from 'react'
import {
  AimIconBottom,
  AimIconCenter,
  AimIconCircle,
  AimIconDottedCircle,
  AimIconLeft,
  AimIconRight,
  AimIconTop,
} from 'src/assets/svgs'
import {
  AnimatableElementProps,
  AnimationContext,
} from 'src/context/AnimationContext'
import { ImageContext } from 'src/context/ImageContextProvider'
import { onMobile, onMobileLandscape, onTablet } from 'src/styles'
import styled, { css } from 'styled-components'
import { FloatIconExposureControl } from '../ExposureControl'
import { LevelDetector } from '../Footer/LevelDetector'

export const Aim: React.FC = () => {
  const { animation, onFocusingAnimationEnd } = useContext(AnimationContext)
  const { level, exposure, setExposure } = useContext(ImageContext)
  return (
    <Container>
      <AimContainer>
        <AimIconArea>
          <AimIconContainer>
            <StyledAimIconCenter />
            <StyledAimIconCircle />
            <StyledAimIconDottedCircle
              id="aimIconDottedCircle"
              className={animation}
            />
            <AimIconOutSideContainer
              id="aimIconOutSide"
              className={animation}
              level={level}
            >
              <AimIconYContainer id="aimIconY" className={animation}>
                <StyledAimIconTop id="aimIconTop" className={animation} />
                <StyledAimIconBottom id="aimIconBottom" className={animation} />
              </AimIconYContainer>
              <AimIconXContainer
                id="aimIconX"
                className={animation}
                level={level}
              >
                <StyledAimIconLeft id="aimIconLeft" className={animation} />
                <StyledAimIconRight
                  onAnimationEnd={onFocusingAnimationEnd}
                  id="aimIconRight"
                  className={animation}
                />
              </AimIconXContainer>
            </AimIconOutSideContainer>
          </AimIconContainer>
        </AimIconArea>
        <ControlContainer>
          <LevelDetector />
          <FloatIconExposureControl
            exposure={exposure}
            setExposure={setExposure}
          />
        </ControlContainer>
      </AimContainer>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow: hidden;
`
const AimContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
`
const AimIconArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`
const AimIconContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${onTablet(css`
    transform: scale(0.8);
  `)}
  ${onMobile(css`
    transform: scale(0.5);
  `)}
  ${onMobileLandscape(css`
    transform: scale(0.4);
  `)}
`
const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  ${onMobile(css`
    margin-bottom: 5px;
  `)}
`

const absoluteCenter = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`
const AimIconOutSideContainer = styled.div<
  AnimatableElementProps & { level: number }
>`
  will-change: transform;
  transition: transform 0.3s;
  ${absoluteCenter}
  ${({ level }) => `transform: rotate(${Math.ceil(level * 9)}deg);`}
`
const AimIconYContainer = styled.div<AnimatableElementProps>`
  ${absoluteCenter}
`
const svgRedStyle = css`
  .bar {
    fill: #df2a2a;
    stroke: #df2a2a;
  }
`
const AimIconXContainer = styled.div<
  AnimatableElementProps & { level: number }
>`
  ${absoluteCenter}
  ${({ level }) => level !== 0 && svgRedStyle}
`
const yDistance = -360
const StyledAimIconTop = styled(AimIconTop)<AnimatableElementProps>`
  ${absoluteCenter}
  top: ${yDistance}px;
`
const StyledAimIconBottom = styled(AimIconBottom)<AnimatableElementProps>`
  ${absoluteCenter}
  bottom: ${yDistance}px;
`
const xDistance = -540
const StyledAimIconLeft = styled(AimIconLeft)<AnimatableElementProps>`
  ${absoluteCenter}
  left: ${xDistance}px;
`
const StyledAimIconRight = styled(AimIconRight)<AnimatableElementProps>`
  ${absoluteCenter}
  right: ${xDistance}px;
`
const StyledAimIconCenter = styled(AimIconCenter)`
  ${absoluteCenter}
`
const StyledAimIconCircle = styled(AimIconCircle)`
  ${absoluteCenter}
`

const StyledAimIconDottedCircle = styled(
  AimIconDottedCircle
)<AnimatableElementProps>`
  ${absoluteCenter}
`
