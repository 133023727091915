import { onMobile } from 'src/styles'
import styled, { css } from 'styled-components'

type Direction = 'left' | 'right'
export const ArrowButton = styled.button<{ direction: Direction }>`
  opacity: 0.5;
  height: 20px;
  border: 10px solid transparent;
  :hover,
  :focus {
    opacity: 1;
  }
  ${({ direction }) => {
    if (direction === 'left') return 'border-right-color: #eaeaea;'
    if (direction === 'right') return 'border-left-color: #eaeaea;'
    return ''
  }}
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
  ${onMobile(css`
    .arrow {
      height: 10px;
      border-width: 5px;
    }
  `)}
`
