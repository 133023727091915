import React from 'react'
import { FacebookIcon, InstagaramIcon } from 'src/assets/svgs'
import { onTablet } from 'src/styles'
import styled, { css } from 'styled-components'

export const Social = () => (
  <Container>
    <a href="https://www.instagram.com/ben_shirakawa" target="_blank">
      <InstagaramIcon />
    </a>
    <a href="https://www.facebook.com/BenShirakawa" target="_blank">
      <FacebookIcon />
    </a>
  </Container>
)
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 23px;
    width: 46px;
    ${onTablet(css`
      margin: 0 17px;
      width: auto;
      height: 34px;
    `)}
  }
`
