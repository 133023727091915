import React from 'react'
import { onMobile } from 'src/styles'
import styled, { css } from 'styled-components'

export const ISO = () => {
  return <ISOContainer>ISO64 1/60 F2.8</ISOContainer>
}

const ISOContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--font-size-xlarge);
  /* ${onMobile(css`
    font-size: var(--font-size-medium);
  `)} */
`
