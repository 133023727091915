import { css, keyframes } from 'styled-components'
import {
  barTransition,
  imageBlurElement,
  imageFilterElement,
  rotateAndScaleElement,
  rotateElement,
  TranslateAnimationOption,
  translateElement,
} from './definitions'

/** アニメーション時間 */
const duration = 2.3

/**
 * imageBlurElement(durationPct: number, blurPx: number)を挿入して背景画像のblurアニメーションを設定します。
 *
 * durationPct = アニメーション時間に対する経過時間割合
 * blurPx = blur半径
 *
 * ex:
 *   duration=5s
 *   ${imageBlurElement(0, 30)}  => 初期はblur半径30
 *   ${imageBlurElement(70, 10)} => 半径30から3.5sec(5sec * 70%)かけて半径10に遷移
 *   ${imageBlurElement(100, 0)} => 半径10から1.5sec(5sec * (100-70)%)かけて半径0(ぼかしなし)に遷移
 */
export const imageBlurAnimation = css`
  animation: imageFocusingBlur ${duration}s ease-in-out;
  @keyframes imageFocusingBlur {
    ${imageBlurElement(0, 5)}
    ${imageBlurElement(30, 5)}
    ${imageBlurElement(40, 25)}
    ${imageBlurElement(60, 15)}
    ${imageBlurElement(95, 20)}
    ${imageBlurElement(100, 0)}
  }
`
export const imageFilterAnimation = css`
  animation: imageFocusingFiler ${duration}s ease-in-out;
  @keyframes imageFocusingFiler {
    ${imageFilterElement(0, 5)}
    ${imageFilterElement(30, 5)}
    ${imageFilterElement(40, 25)}
    ${imageFilterElement(60, 15)}
    ${imageFilterElement(95, 20)}
    ${imageFilterElement(100, 0)}
  }
`
/**
 * rotateElement(duration: number, degree: number, reverse?:boolean)を挿入して回転アニメーションを設定します。
 *
 * duration = アニメーション時間に対する経過時間割合
 * degree = 初期状態からみた到達回転角度
 * reverse = trueの場合逆回転（ここでは渡すだけ）
 *
 * ex:
 *   duration=5s
 *   ${rotateElement(0, 0, reverse)}    => 初期は要素の初期位置
 *   ${rotateElement(30, 540, reverse)} => 要素の初期位置から1.5sec(5sec * 30%)かけて540度回転(1.5回転)
 *   ${rotateElement(70, 180, reverse)} => 540度から2.0sec(5sec * (70-30%)かけて180度回転に戻す(逆方向に1.0回転)
 *   ${rotateElement(100, 0, reverse)}  => 180度から1.5sec(5sec * (100-70)%)かけて初期位置に戻す(0.5回転)
 */
export const rotateAnimation = (reverse?: boolean) => css`
  animation: aimIconFocusing ${duration}s ease-in-out;
  @keyframes aimIconFocusing {
    ${rotateElement(0, 0, reverse)}
    ${rotateElement(30, 450, reverse)}
    ${rotateElement(40, 180, reverse)}
    ${rotateElement(60, 90, reverse)}
    ${rotateElement(75, 0, reverse)}
    ${rotateElement(100, 0, reverse)}
  }
`
export const rotateAnimationY = (reverse?: boolean) => css`
  animation: aimIconYFocusing ${duration}s ease-in-out;
  @keyframes aimIconYFocusing {
    ${rotateElement(0, 0, reverse)}
    ${rotateElement(30, 450, reverse)}
    ${rotateElement(40, 180, reverse)}
    ${rotateElement(60, 0, reverse)}
    ${rotateElement(75, 0, reverse)}
    ${rotateElement(100, 0, reverse)}
  }
`
export const rotateAnimationDottedCircle = (reverse?: boolean) => css`
  animation: aimIconDottedCirleFocusing ${duration}s ease-in-out;
  @keyframes aimIconDottedCirleFocusing {
    ${rotateAndScaleElement(0, 0, 1, reverse)}
    ${rotateAndScaleElement(30, 45, 0.8, reverse)}
    ${rotateAndScaleElement(40, 15, 0.9, reverse)}
    ${rotateAndScaleElement(60, 0, 1, reverse)}
    ${rotateAndScaleElement(75, 58, 1.1, reverse)}
    ${rotateAndScaleElement(100, 0, 1, reverse)}
  }
`
/**
 * @param xTo: number 水平方向の最大移動距離
 * @param yTo: number 垂直方向の最大移動距離
 *
 * translateElement(durationPct: number, x: number, y: number)を挿入して移動アニメーションを設定します。
 *
 * durationPct = アニメーション時間に対する経過時間割合
 * x = 水平方向の移動
 * y = 垂直方向方向の移動
 */
const translateKeyframes = ({
  xTo = 0,
  yTo = 0,
}: TranslateAnimationOption) => keyframes`
  ${translateElement(0, 0, 0)}
  ${translateElement(5, xTo / 1, yTo / 1)}
  ${translateElement(45, xTo / 1, yTo / 1)}
  ${translateElement(65, xTo / 1, yTo / 1)}
  ${translateElement(70, xTo / 2, yTo / 2)}
  ${translateElement(75, xTo / 3, yTo / 3)}
  ${translateElement(95, xTo / 3, yTo / 3)}
  ${translateElement(100, 0, 0)}
`
export const translateAnimation = (option: TranslateAnimationOption) => css`
  animation: ${translateKeyframes(option)} ${duration}s linear;
`

export const scaleAnimation = (option: TranslateAnimationOption) => css`
  animation: ${translateKeyframes(option)} ${duration}s linear;
`
/**
 * 水準器の水平バーが伸びてくるアニメーション
 */
export const barTransitionAnimation = css`
  ${barTransition(0.45, duration + 0.0)}
`
