import React, {
  createContext,
  CSSProperties,
  FC,
  useEffect,
  useState,
} from 'react'
import { getImage, getImages, ImageType } from 'src/components/Image'
import {
  defaultGlitchQueue,
  GlitchAction,
} from 'src/components/Image/Glitch/GlitchQueue'
import { exposureToBrightness, randomize } from './util'

type ImageContextProps = {
  bgImage: ImageType | undefined
  setBgImage: (bgImage: ImageType) => void
  bgOptions: CSSProperties
  setBgOptions: (bgOptions: CSSProperties) => void
  bgGlitchAction: GlitchAction
  toNextAction: () => void
  level: number
  setLevel: (level: number) => void
  exposure: number
  setExposure: (exposure: number) => void
}

export const ImageContext = createContext<ImageContextProps>({
  bgImage: getImage(0),
  setBgImage: () => {},
  bgOptions: {},
  setBgOptions: () => {},
  toNextAction: () => {},
  level: 0,
  setLevel: () => {},
  exposure: 0,
  setExposure: () => {},
  bgGlitchAction: GlitchAction.wait(-1),
})

const images: ImageType[] = []

const nextImage = (image?: ImageType) => {
  if (images.length) return images.shift() || getImage(0)
  const newImages = getImages().filter((newImage) => newImage !== image)
  const nextImage = image || newImages.shift()
  images.push(...randomize(newImages))
  return nextImage || getImage(0)
}

export const ImageContextProvider: FC = ({ children }) => {
  const [bgImage, setBgImage] = useState<ImageType | undefined>()
  const [bgOptions, setBgOptions] = useState({})
  const [level, setLevel] = useState(0)
  const [exposure, setExposure] = useState(0)
  const [hasFirstLoadCompleted, setHasFirstLoadCompleted] = useState(false)
  const [glitchAction, setGlitchAction] = useState(defaultGlitchQueue.current)
  const toNextAction = () => {
    if (!hasFirstLoadCompleted) setHasFirstLoadCompleted(true)
    if (!glitchAction.shouldChangeImage) return
    setGlitchAction(defaultGlitchQueue.next())
  }
  useEffect(() => {
    setBgImage(nextImage(getImage(0)))
  }, [])
  useEffect(() => {
    if (glitchAction.shouldChangeImage) {
      setBgImage(nextImage())
      return
    }
    setTimeout(() => {
      setGlitchAction(defaultGlitchQueue.next())
    }, glitchAction.duration)
  }, [glitchAction])
  useEffect(() => {
    setBgOptions({
      filter: `brightness(${exposureToBrightness(exposure)})`,
    })
  }, [exposure])
  return (
    <ImageContext.Provider
      value={{
        bgImage,
        setBgImage,
        bgOptions,
        setBgOptions,
        level,
        setLevel,
        exposure,
        setExposure,
        bgGlitchAction: glitchAction,
        toNextAction,
      }}
    >
      {hasFirstLoadCompleted &&
        getImages().map((img) => (
          <link key={img} rel="preload" href={`/images/${img}`} as="image" />
        ))}
      {children}
    </ImageContext.Provider>
  )
}
