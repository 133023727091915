import React from 'react'
import { onTablet } from 'src/styles'
import styled, { css } from 'styled-components'

export const Frame = () => {
  return (
    <FrameArea>
      <FrameSideArea>
        <FrameTopLeft />
        <FrameTopRight />
      </FrameSideArea>
      <FrameSideArea>
        <FrameBottomLeft />
        <FrameBottomRight />
      </FrameSideArea>
    </FrameArea>
  )
}

const FrameArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`
const FrameSideArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const frameSize = css`
  border: 5px solid #eaeaea;
  width: 100px;
  height: 100px;
  ${onTablet(css`
    border: 2px solid #eaeaea;
    width: 50px;
    height: 50px;
  `)}
`
const FrameTopLeft = styled.div`
  ${frameSize}
  border-right: none !Important;
  border-bottom: none !important;
`
const FrameTopRight = styled.div`
  ${frameSize}
  border-left: none !Important;
  border-bottom: none !important;
`

const FrameBottomLeft = styled.div`
  ${frameSize}
  border-top: none !Important;
  border-right: none !important;
`
const FrameBottomRight = styled.div`
  ${frameSize}
  border-top: none !Important;
  border-left: none !important;
`
