import React, { createContext, FC, useState } from 'react'
import styled from 'styled-components'
import { imageFocusingAnimation } from './focusing'

export type AnimationType = 'focusing' | ''

type AnimationContextProps = {
  animation: AnimationType
  setAnimation: (animation: AnimationType) => void
  onFocusingAnimationEnd: VoidFunction
}
export const AnimationContext = createContext<AnimationContextProps>({
  animation: 'focusing',
  setAnimation: () => {},
  onFocusingAnimationEnd: () => {},
})
export const AnimationContextProvider: FC = ({ children }) => {
  const [animation, setAnimation] = useState<AnimationType>('focusing')
  const onAnimationEnd = () => setAnimation('')
  return (
    <AnimationContext.Provider
      value={{
        animation: animation,
        setAnimation: setAnimation,
        onFocusingAnimationEnd: onAnimationEnd,
      }}
    >
      <AnimationContainer>{children}</AnimationContainer>
    </AnimationContext.Provider>
  )
}
const AnimationContainer = styled.div`
  height: 100%;
  ${imageFocusingAnimation}
`
