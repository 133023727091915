import { useWindowSize } from '@react-hook/window-size'
import React from 'react'
import styled from 'styled-components'

export const Angle = () => {
  const [width, height] = useWindowSize()
  return (
    <AngleContainer>
      {width}×{height}
    </AngleContainer>
  )
}

const AngleContainer = styled.div`
  font-size: var(--font-size-xlarge);
  text-align: right;
`
