export const randomize = <T>(array: T[]) => {
  const clonedArray = [...array]
  for (var i = clonedArray.length - 1; i > 0; i--) {
    var r = Math.floor(Math.random() * (i + 1))
    var tmp = clonedArray[i]
    clonedArray[i] = clonedArray[r]
    clonedArray[r] = tmp
  }
  return clonedArray
}

export const exposureToBrightness = (exposure: number) => 1 + exposure * 0.1
