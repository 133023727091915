import { navigate } from 'gatsby'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { About } from './About'
import { ContainerWrapper } from './Container'
import { HeaderMenu, HederMenuProps } from './HeaderMenu'
import { Play } from './Play'
import { Social } from './Social'

export const ControlItems = ['PLAY', 'MENU'] as const
export const MenuItems = ['WORKS', 'ABOUT', 'SOCIAL'] as const
export type MenuItem =
  | typeof ControlItems[number]
  | typeof MenuItems[number]
  | 'CLOSE'
  | 'CLOSE_PLAY'
  | ''

export const MenuContents: FC<HederMenuProps> = ({
  openedItem,
  toggleItem,
}) => {
  const [hideContent, setHideContent] = useState(false)
  useEffect(() => {
    if (openedItem === 'MENU') setHideContent(false)
  }, [openedItem])
  return (
    <MenuContentContainer onClick={(e) => e.stopPropagation()}>
      <ContainerWrapper className={openedItem} hidden={hideContent}>
        <MenuContent
          openedItem={openedItem}
          toggleItem={toggleItem}
          hide={() => setHideContent(true)}
        />
      </ContainerWrapper>
    </MenuContentContainer>
  )
}
const MenuContentContainer = styled.div`
  position: relative;
`

type MenuContentProps = HederMenuProps & {
  hide: VoidFunction
}
const MenuContent: React.FC<MenuContentProps> = ({
  openedItem,
  toggleItem,
  hide,
}) => {
  if (openedItem === 'PLAY') return <Play />
  if (openedItem === 'MENU')
    return <HeaderMenu openedItem={openedItem} toggleItem={toggleItem} />
  if (openedItem === 'WORKS') {
    navigate('/works/')
    return <HeaderMenu openedItem={openedItem} toggleItem={toggleItem} />
  }
  if (openedItem === 'ABOUT') return <About />
  // if (openedItem === 'CONTACT')
  //   return <Contact close={(e) => toggleItem(e, 'MENU')} hide={hide} />
  if (openedItem === 'SOCIAL') return <Social />
  return <></>
}
