import React, { FC, useEffect, useState } from 'react'
import { onMobile, onMobileLandscape, onTablet } from 'src/styles'
import styled, { css } from 'styled-components'
import {
  contentFadeInAnimation,
  menuFadeInAnimation,
  menuFadeOutAnimation,
} from './anmation'

type ContainerProps = { className?: string; hidden?: boolean }
export const ContainerWrapper: FC<ContainerProps> = ({
  children,
  className = 'NONE',
  hidden,
}) => {
  const [transitionEnded, setTransitionEnded] = useState(false)
  const [menuAnimationEnded, setMenuAnimationEnded] = useState(false)
  const [animationEnded, setAnimationEnded] = useState(false)
  useEffect(() => {
    setAnimationEnded(false)
    setMenuAnimationEnded(false)
    setTransitionEnded(false)
  }, [className])
  return (
    <MenuContentContainer>
      <MenuContentBox
        className={`${className} ${hidden ? 'hidden' : ''}`}
        onTransitionEnd={() => {
          setAnimationEnded(false)
          setTransitionEnded(true)
        }}
        onAnimationEnd={() => {
          setTransitionEnded(false)
          setMenuAnimationEnded(true)
        }}
        transitionEnded={transitionEnded}
        animationEnded={animationEnded}
      >
        <MenuContentWrapper
          className={className}
          onTransitionEnd={() => {
            setAnimationEnded(false)
            setTransitionEnded(false)
          }}
          onAnimationEnd={() => {
            setTransitionEnded(false)
            setMenuAnimationEnded(false)
          }}
          transitionEnded={transitionEnded}
          animationEnded={menuAnimationEnded}
        >
          {children}
        </MenuContentWrapper>
      </MenuContentBox>
    </MenuContentContainer>
  )
}
const MenuContentContainer = styled.div<ContainerProps>`
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 2;
  overflow: hidden;
  ${onTablet(css`
    top: 40px;
  `)}
`

type MenuContentBoxProps = {
  transitionEnded?: boolean
  animationEnded?: boolean
}
const MenuContentBox = styled.div<MenuContentBoxProps>`
  background-color: rgba(0, 0, 0, 34%);
  border-radius: 12px;
  padding: 30px;
  transition: width 0.5s, height 0.5s;
  width: 212px;
  height: 212px;
  &.PLAY {
    padding: 30px 18px;
    width: 890px;
    height: 214px;
  }
  &.ABOUT {
    width: 469px;
    height: 469px;
  }
  &.CONTACT {
    width: 420px;
    height: 711px;
  }
  &.SOCIAL {
    width: 244px;
    height: 110px;
  }

  ${onTablet(css`
    padding: 27px 15px;
    width: 128px;
    height: 180px;
    &.PLAY {
      width: 684px;
      height: 214px;
    }
    &.ABOUT {
      width: 308px;
      height: 370px;
    }
    &.CONTACT {
      padding: 14px 5px;
      width: 308px;
      height: 550px;
    }
    &.SOCIAL {
      width: 128px;
      height: 64px;
    }
  `)}
  ${onMobile(css`
    padding: 23px 5px;
    width: 112px;
    height: 160px;
    &.ABOUT {
      padding: 7px 5px;
      width: 308px;
      height: 240px;
    }
    &.PLAY {
      padding: 15px 5px;
      width: 82vw;
      max-width: 360px;
      height: 109px;
    }
    &.CONTACT {
      width: 308px;
      height: 510px;
    }
    &.SOCIAL {
      padding: 14px;
      width: 128px;
      height: 64px;
    }
  `)}
  ${onMobileLandscape(css`
    &.CONTACT {
      padding: 8px 5px;
      width: 500px;
      height: 240px;
    }
  `)}
  overflow: hidden;
  ${menuFadeInAnimation}
  ${menuFadeOutAnimation}
`

const MenuContentWrapper = styled.div<MenuContentBoxProps>`
  height: 100%;
  clip-path: inset(0 0 100%);
  ${({ animationEnded, transitionEnded }) =>
    (animationEnded || transitionEnded) && contentFadeInAnimation}
`
