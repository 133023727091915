import React, { useCallback, useState } from 'react'
import { Aim } from 'src/components/Aim'
import { Background } from 'src/components/Background'
import { FinderOverlay } from 'src/components/FinderOverlay'
import { Footer } from 'src/components/Footer'
import { Header } from 'src/components/Header'
import { MenuItem } from 'src/components/MenuContent'
import { SEO } from 'src/components/seo'
import { GlobalStyles, onMobilePortrait } from 'src/styles'
import styled, { css } from 'styled-components'

export const TopLayout = () => (
  <Layout>
    <GlobalStyles />
    <Background />
    <Contents />
    <SEO />
  </Layout>
)
const Contents = () => {
  const [openedItem, setOpenItem] = useState<MenuItem>('')
  const closeItem = () => {
    if (openedItem === 'PLAY') return setOpenItem('CLOSE_PLAY')
    setOpenItem('CLOSE')
  }
  const toggleItem = useCallback(
    (e: React.MouseEvent | undefined, item: MenuItem) => {
      e && e.stopPropagation()
      if (openedItem !== item) {
        setOpenItem(item)
        return
      }
      closeItem()
      // @ts-ignore
      if (e && e.clientX) e.currentTarget.blur()
    },
    [openedItem]
  )
  return (
    <Container onClick={closeItem}>
      <Aim />
      <FinderOverlay />
      <Header openedItem={openedItem} toggleItem={toggleItem} />
      <Footer />
    </Container>
  )
}

const Layout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5vh;
  ${onMobilePortrait(css`
    padding: 5vw;
  `)}
  * {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
