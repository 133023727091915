import React, { FC } from 'react'
import { ExposureControlIcon } from 'src/assets/svgs'
import styled from 'styled-components'

export const ExposureDisplay: FC<{ exposure: number }> = ({ exposure }) => {
  return (
    <ExposureContainer>
      <ExposureControlIcon />
      <ExposureValue>{withSign(exposure, 1)}</ExposureValue>
    </ExposureContainer>
  )
}
const sign = (num: number) => {
  if (num === 0) return '±'
  if (num > 0) return '+'
  return ''
}
const withSign = (num: number, fractionDigits: number) =>
  `${sign(num)}${num.toFixed(fractionDigits)}`

const ExposureContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const ExposureValue = styled.div`
  margin-left: 10px;
  font-size: var(--font-size-small);
`
