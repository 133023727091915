const NEW_LINE = '%0D%0A'
export class ContactFormInfo {
  constructor(
    readonly name: string,
    readonly email: string,
    readonly phone: string,
    readonly message: string
  ) {}

  static initialize = () => {
    return new ContactFormInfo('', '', '', '')
  }
  static new = (
    name: string,
    email: string,
    phone: string,
    message: string
  ) => {
    return new ContactFormInfo(name, email, phone, message)
  }

  mail = () =>
    [
      this.message,
      `${NEW_LINE}--------------------${NEW_LINE}`,
      `Name: ${this.name}`,
      `Email: ${this.email}`,
      this.phone && `Phone: ${this.phone}`,
    ]
      .filter(Boolean)
      .join(NEW_LINE)
}
