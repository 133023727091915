import React, { FC } from 'react'
import styled from 'styled-components'

type ExposureControlProps = {
  exposure: number
  setExposure: (exposure: number) => void
}
const DIAL_SIDE_LENGTH = 9
export const TICK = 3
const DIAL_STEP = 1
const DIAL_MAX = (5 * DIAL_SIDE_LENGTH) / TICK
const DIAL_MIN = (-5 * DIAL_SIDE_LENGTH) / TICK
const DELIMITER = '.'
const DIAL_ARRAY = new Array(DIAL_SIDE_LENGTH).fill(DELIMITER)
// TODO refactor
const genDial = (current: number, isLeft?: boolean) => {
  return DIAL_ARRAY.map((_, idx) => {
    const value = isLeft
      ? current - (DIAL_SIDE_LENGTH - idx)
      : current + idx + 1
    if (value < DIAL_MIN || DIAL_MAX < value) return ''
    const dialValue = value / TICK
    if (!Number.isInteger(dialValue)) return DELIMITER
    const dialAbs = Math.abs(dialValue)
    if (
      value !== 0 &&
      ((isLeft && dialValue === 1) ||
        (!isLeft && dialValue === -1) ||
        value === DIAL_MAX ||
        value === DIAL_MIN ||
        Math.abs(current / TICK - dialValue) > 2)
    )
      return dialValue < 0 ? `\u207b${dialAbs}` : `\u207a${dialAbs}`
    return dialAbs
  })
}
export const FixedIconExposureControl: FC<ExposureControlProps> = ({
  exposure,
  setExposure,
}) => {
  return (
    <ExposureControlContainer>
      <ExposureControlBox>
        <ExposureControlSlider>
          <SliderLeft>{genDial(exposure, true)}</SliderLeft>
          <SliderCenter />
          <SliderRight>{genDial(exposure)}</SliderRight>
        </ExposureControlSlider>
        <ExposureControlInput
          type="range"
          max={DIAL_MAX}
          min={DIAL_MIN}
          step={DIAL_STEP}
          value={exposure}
          onChange={(e) => setExposure(-e.target.value)}
        />
      </ExposureControlBox>
    </ExposureControlContainer>
  )
}

const ExposureControlContainer = styled.div`
  display: flex;
  justify-content: center;
`
const ExposureControlBox = styled.div`
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  padding: 0px 10px 10px;
  font-size: var(--font-size-medium);
  width: auto;
  height: 67px;
  display: flex;
  flex-direction: column-reverse;
`
const ExposureControlInput = styled.input`
  appearance: none;
  width: 100%;
  height: 10px;
  padding: 20px 0 42px;
  margin: 0;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    width: 1px;
  }
`
const ExposureControlSlider = styled.div`
  display: flex;
  justify-content: center;
  width: 242px;

  font-size: var(--font-size-medium);
  margin-top: -44px;
`
const SliderLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
`
const SliderRight = styled.div`
  flex: 1;
  overflow: hidden;
`
const SliderCenter = styled.div`
  width: 14px;
  height: 18px;
  height: auto;
  position: relative;
  overflow: hidden;
  ::before {
    content: '';
    --borderTop: 15px;
    --borderCoef: 1.4;
    position: absolute;
    bottom: 10px;
    left: -14px;
    margin: auto;
    border-top: var(--borderTop) solid #eaeaea;
    border-left: calc(var(--borderTop) * var(--borderCoef)) solid transparent;
    border-right: calc(var(--borderTop) * var(--borderCoef)) solid transparent;
  }
`
