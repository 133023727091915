import React, { useContext, useEffect, useRef, useState } from 'react'
import { ArrowButton } from 'src/components/Button'
import { getImages, StaticImageDisplay } from 'src/components/Image'
import { ImageContext } from 'src/context/ImageContextProvider'
import { onMobile } from 'src/styles'
import styled, { css } from 'styled-components'

export const Play = () => {
  const { setBgImage } = useContext(ImageContext)
  const [animationEnded, setAnimationEnded] = useState(false)
  const imagesContainerEl = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setTimeout(() => {
      setAnimationEnded(true)
    }, 500)
  }, [])
  return (
    <Container>
      {animationEnded && (
        <>
          <ArrowButton
            direction="left"
            onClick={() => {
              if (imagesContainerEl) imagesContainerEl.current?.scrollBy(-75, 0)
            }}
          />
          <ImagesContainer ref={imagesContainerEl}>
            {getImages().map((image) => (
              <ImageContainer
                key={image}
                tabIndex={0}
                onClick={() => setBgImage(image)}
              >
                <StaticImageDisplay image={image} />
              </ImageContainer>
            ))}
            <div style={{ paddingLeft: '1px' }} />
          </ImagesContainer>
          <ArrowButton
            direction="right"
            onClick={() => {
              if (imagesContainerEl) imagesContainerEl.current?.scrollBy(75, 0)
            }}
          />
        </>
      )}
    </Container>
  )
}
const Container = styled.div`
  max-width: 904px;
  display: flex;
  align-items: center;
`
const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: scroll;
  margin: 0px 24px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  ${onMobile(css`
    margin: 0 12px;
  `)}
`
const ImageContainer = styled.div`
  scroll-snap-align: start end;

  padding: 2px 10px;
  cursor: pointer;
  .gatsby-image-wrapper {
    width: 150px;
    height: 150px;
  }
  :hover {
    outline: none;
    .gatsby-image-wrapper {
      outline: solid 2px #eaeaea;
    }
  }
  :focus {
    outline: none;
  }
  ${onMobile(css`
    padding: 2px 5px;
    .gatsby-image-wrapper {
      width: 75px;
      height: 75px;
    }
  `)}
`
