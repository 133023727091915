import React, { FC } from 'react'
import { onMobile, onTablet } from 'src/styles'
import styled, { css } from 'styled-components'

type FloatIconExposureControlProps = {
  exposure: number
  setExposure: (exposure: number) => void
}
export const FloatIconExposureControl: FC<FloatIconExposureControlProps> = ({
  exposure,
  setExposure,
}) => {
  return (
    <FloatIconExposureControlContainer>
      <FloatIconExposureControlBox>
        <FloatIconExposureControlSlider>
          {'\u207b3..2..1..0..1..2..\u207a3'}
        </FloatIconExposureControlSlider>
        <FloatIconExposureControlInput
          type="range"
          min={-3}
          max={3}
          step={0.1}
          value={exposure}
          onChange={(e) => setExposure(+e.target.value)}
        />
      </FloatIconExposureControlBox>
    </FloatIconExposureControlContainer>
  )
}

const FloatIconExposureControlContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 4;
`
const FloatIconExposureControlBox = styled.div`
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  padding: 0 24px 10px;
  font-size: var(--font-size-small);
  width: 224px;
  height: 67px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  ${onTablet(css`
    width: 150px;
    height: 56px;
    padding: 0 12px 5px;
  `)}
  ${onMobile(css`
    width: 100px;
    height: 45px;
    padding: 0 5px 5px;
    font-size: var(--font-size-xsmall);
  `)}
`
const FloatIconExposureControlInput = styled.input`
  appearance: none;
  width: 100%;
  height: 1px;
  padding: 20px 0 46px;
  margin: 0;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 36px;
    height: 64px;
    margin-top: 24px;
    padding-bottom: 16px;
    border-width: 18px;
    border-style: solid;
    border-style: solid;
    border-color: #f2f2f2 transparent transparent;
    background-color: transparent;
    clip-path: inset(10% 0 0);
  }
  ${onTablet(css`
    padding: 15px 0 39px;
    &::-webkit-slider-thumb {
      width: 24px;
      height: 42px;
      margin-top: 20px;
      border-width: 12px;
    }
  `)}
  ${onMobile(css`
    padding: 10px 8px 32px;
    width: 115%;
  `)}
`
const FloatIconExposureControlSlider = styled.div`
  white-space: pre-wrap;
  text-align: center;
  line-height: 0;
  margin-top: -33px;
  ${onTablet(css`
    margin-top: -27px;
  `)}
  ${onMobile(css`
    margin-top: -21px;
  `)}
  cursor: none;
`
