import React, { FC, useContext, useMemo, useState } from 'react'
import { AnimationContext, AnimationType } from 'src/context/AnimationContext'
import { ImageContext } from 'src/context/ImageContextProvider'
import { onTablet } from 'src/styles'
import styled, { css } from 'styled-components'
import useInterval from 'use-interval'

const MeterArray = Array.from(new Array(20)).map((_, i) => i)
export const Audio = () => {
  const { bgGlitchAction } = useContext(ImageContext)
  return (
    <AudioContainer>
      <AudioMeter side="L" onGlitch={bgGlitchAction.shouldGlitch} />
      <AudioMeter side="R" onGlitch={bgGlitchAction.shouldGlitch} />
    </AudioContainer>
  )
}

const VOLUME_STEP = 0.5
const increment = (
  current: number,
  animation: AnimationType,
  onGlitch: boolean
) => {
  if (animation) return current > 0 ? current - VOLUME_STEP : current
  const rand = Math.floor(Math.random() * 3)
  if (current < MeterArray.length && onGlitch) return current + VOLUME_STEP * 4
  if (current > MeterArray.length / 2) return current - VOLUME_STEP * 4
  if (current < MeterArray.length / 2 && rand >= 2) return current + VOLUME_STEP
  if (current > 0 && rand === 1) return current - VOLUME_STEP
  return current
}
const AudioMeter: React.FC<{ side: 'L' | 'R'; onGlitch: boolean }> = ({
  side,
  onGlitch,
}) => {
  const [volume, setVolume] = useState(0)
  const [peakQueue, setPeakQueue] = useState([0, 0, 0])
  const peak = useMemo(
    () =>
      peakQueue.reduce((prev, current) => (current > prev ? current : prev), 0),
    [peakQueue]
  )
  const { animation } = useContext(AnimationContext)
  useInterval(() => {
    setVolume(increment(volume, animation, onGlitch))
  }, 100)
  useInterval(() => {
    setPeakQueue([peakQueue[1], peakQueue[2], volume])
  }, 1000)
  return (
    <AudioMeterContainer>
      <Side>{side}</Side>
      <Ticks side={side} volume={volume} peak={peak} />
    </AudioMeterContainer>
  )
}

const genTickClass = (idx: number, volume: number, peak: number) => {
  if (volume > idx) return ''
  if (volume && volume + VOLUME_STEP > idx) return 'half'
  if (idx === peak - 1) return 'peak'
  return 'disabled'
}
type TicksProps = {
  side: string
  volume: number
  peak: number
}
const Ticks: FC<TicksProps> = ({ side, volume, peak }) => (
  <TickContainer>
    {MeterArray.map((idx) => (
      <div key={`${side}${idx}`} className={genTickClass(idx, volume, peak)} />
    ))}
  </TickContainer>
)

const AudioContainer = styled.div`
  margin: 0 0 10px -20px;
  ${onTablet(css`
    margin: 0 0 5px -10px;
  `)}
`
const Side = styled.div`
  width: 6px;
`
const AudioMeterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  font-size: var(--font-size-small);
`
const TickContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  div {
    margin: 3px;
    border: 1px solid #eaeaea;
    background: #eaeaea;
    width: 5px;
    height: 18px;
    @media screen and (max-width: 1023px) {
      margin: 1px;
      width: 3px;
    }
  }
  .half {
    opacity: 0.5;
  }
  .peak {
    background: none;
  }
  .disabled {
    background: none;
    opacity: 0.36;
  }
`
