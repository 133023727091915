import React, { useContext } from 'react'
import { ImageContext } from 'src/context/ImageContextProvider'
import { onTablet } from 'src/styles'
import styled, { css } from 'styled-components'
import { Angle } from './Angle'
import { Audio } from './Audio'
import { ExposureDisplay } from './ExposureDisplay'
import { ISO } from './ISO'

export const Footer = () => {
  const { exposure } = useContext(ImageContext)
  return (
    <FooterContainer>
      <FrameElement>
        <Audio />
        <FooterControl>
          <ExposureDisplay exposure={exposure} />
          <ISO />
        </FooterControl>
      </FrameElement>
      <FrameElement>
        <Angle />
      </FrameElement>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  z-index: 2;
  display: flex;
  justify-content: space-between;
`

const FrameElement = styled.div`
  flex-basis: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 20px 20px;
  ${onTablet(css`
    margin: 5px 10px;
    font-size: var(--font-size-small);
    svg {
      width: 15%;
    }
  `)}
`

const FooterControl = styled.div``
